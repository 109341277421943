import React from 'react'

function Headline(props) {
    return (
        <div>
             <h3 style={{color:"#00000 !important"}}>{props.fElement} {props.sElement}</h3>
             
        </div>
    )
}

export default Headline
