import React from 'react'
import './AdminHead.css'
function AdminHead() {
    return (
        <div className="admin__head">
            <h3>Bienvenue l'admin</h3>
        </div>
    )
}

export default AdminHead
